import React, { useState, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, InputGroup, Input, Form } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { UseEmailContext } from 'contexts/userEmail'
import { LoadingContext } from 'contexts/loading'
import { $unsecurePost } from 'components/hooks/useAxios'
import useApiCall from 'components/api/useApiCall'
import Skeleton from 'react-loading-skeleton'
import ErrorHandler from '../Layout/errorHandler'
import ContentHolder from 'components/layouts/ContentHolder'
import TitleHolder from 'components/layouts/TitleHolder'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'
import toastConfig from 'configs/toastConfig'
import Icon from 'components/layouts/Icon'
import currencyFormatter from 'currency-formatter'
import expired from 'assets/media/Expired.svg'
import { saveAs } from 'file-saver'
import { IPP_Modal } from './IppModal'
import { IPP } from './Ipp'
import { CancelSubscriptionModal } from './cancelSubscriptionModal'
import { config } from 'configs/constants'

const Account = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [userEmail] = useContext(UseEmailContext)
  let [{ error, isLoaded, data }] = useApiCall('subscriptions')
  const [selected, setSelected] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [ippModal, setIppModal] = useState(false)
  //modal//
  const [loading, setLoading] = useContext(LoadingContext)
  const [password, setPassword] = useState('')
  const [confPassword, setConfPassword] = useState('')
  const [autofillFocused, setAutofillFocused] = useState('')
  const [serialNumber, setSerialNumber] = useState([])
  const [currSerialNumber, setCurrSerialNumber] = useState('')
  const [currSubscription, setCurrSubscription] = useState('')
  const [currIndex, setCurrIndex] = useState(-1)
  const [ippInfo,setIppInfo] = useState(()=>createDefaultIPPInfo())

  const loginBtn = useRef(null)
  const passwordRef = useRef(null)
  const emailRef = useRef(null)

  const detectAutofill = (...args) => {
    setTimeout(() => {
      args.forEach((element) => {
        if (element.current.matches(':-internal-autofill-selected')) {
          setAutofillFocused('focused')
        }
      })
    }, 400)
  }

  const toggleModal = (ippSerialNumber, index) => {
    /*if (serialNumber.find((sn) => sn.ippSerialNumber === ippSerialNumber)) return*/
    setCurrSerialNumber(ippSerialNumber)
    setCurrIndex(index)
    detectAutofill(passwordRef, emailRef)
    setIppModal(true)
  }

  let url = `${config.urls.ACCOUNT_URL}/api/user/ipp-reveal`

  const handleinput = (input, type) => {
    if (type === 'password') {
      setPassword(input.target.value)
    }

    if (type === 'conf_password') {
      setConfPassword(input.target.value)
    }
  }

  const checkInput = () => {
    if (password.length === 0) {
      toastConfig.fire({
        icon: 'error',
        title: t('account.enter_password'),
      })
      return false
    }

    if (password.length > 0) {
      return true
    }
  }
	
  /* AW-3371 Ugly and insane but I didn't find a way to create dynamic useState having array of dict, just hoping that customer don't have more than one hundred subscriptions! */
  function createDefaultIPPInfo() {
  	let defaultIPPInfo = []
  	for(let i=0;i<100;i++) { defaultIPPInfo.push({"l":1,"u":"","p":""}) }
  	return defaultIPPInfo
  }
  
  
  const handleIppReply = () => {
    if (checkInput()) {
      setLoading(true)
      const token = window.localStorage.getItem('h')
      $unsecurePost(url, { api_token: token, password, ipp_credentials: currSerialNumber }).then(
        (result) => {
          setLoading(false)
          setIppModal(false)
          setPassword('')
          setIppInfo(ippInfo.map((info,i) => (i==currIndex ? {"l":0,"u":result.message.username,"p":result.message.password} : info)))
        },
        (error) => {
          toastConfig.fire({
            icon: 'error',
            title: t('account.pass_fail'),
          })
          setLoading(false)
        }
      )
    }
  }
  
  
  const isIPPItem = (item) => {
  		return item.sku === 'IPP_1Y' || item.sku === 'IPP_1Y_W'
  }
  
  {/* AW-3325 */}
  
  const isItemActive = (item) => {
  		return item.isActive && ! item.isCanceled
  }
  
  const handleCancelSubscription = (reference) => {

  	setLoading(true)
  	
  	let url = `${config.urls.ACCOUNT_URL}/api/user/subscription-cancel?subscription=${reference}&api_token=${window.localStorage.h}`
    
    $unsecurePost(url).then(
      (result) => {
        setLoading(false)
        toastConfig.fire({
          icon: 'success',
          title: t('subscription.canceled'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      },
      (error) => {
        setLoading(false)
        toastConfig.fire({
          icon: 'error',
          title: t('general.err'),
        })
      }
    )
  }


  const handleCollapse = (id) => {
    id === selected ? setSelected('') : setSelected(id)
  }
  const handleMobile = () => {
    if (window.innerWidth > 991) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }
  const savePdf = (url) => {
    saveAs(`${url}`, 'invoice.pdf')
  }
  useEffect(() => {
    handleMobile()
  }, [])
  return (
    <>
      <div className="page--account">
        <ContentHolder>
          <>
            <TitleHolder text={t('account.title')} icon="account" back={'/'} pageTitle={true} />
            <Row className="card-wrapper-row col-12">
              <Col sm={12}>
                <TitleHolder text={t('account.profile')} styles="subtitle" />
              </Col>
              <Col lg={6} md={12} className="card-label-wrapper spreader">
                <div className="card-label">
                  <div className="label">{t('account.primary_email')}:</div>
                  <div className="value">{userEmail}</div>
                </div>

                {/* Change Email */}
                <ChangeEmail />
              </Col>
              <div className="divider"></div>
              {/* Password */}
              <Col lg={5} md={12} className="card-label-wrapper offset-lg-1">
                <div className="card-label">
                  <div className="label">{t('account.password')}</div>
                </div>

                {/* Change Password */}
                <ChangePassword />
              </Col>
            </Row>
            <Container className="p-0">
              {/* LOADING */}
              {!isLoaded && (
                <>
                  <div className="card-wrapper-row d-flex desktop">
                    <div className="col-6 d-flex flex-column">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={1} width={200} />
                      <Skeleton count={1} width={300} />
                      <Skeleton count={1} width={250} />
                      <Skeleton count={1} width={300} />
                      <Skeleton count={1} width={300} />
                    </div>
                    <div className="col-6 col-sm-0 d-flex flex-column right-loader pl-2">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={1} width={200} />
                      <Skeleton count={1} width={300} />
                      <Skeleton count={1} width={250} />
                      <Skeleton count={1} width={300} />
                      <Skeleton count={1} width={300} />
                    </div>
                  </div>
                </>
              )}
              {/* END OF LOADING */}
			  
              {data?.message?.subscriptions.map((item, index) => (
                <Row className={selected === item.reference ? 'card-wrapper-row subs collapsed' : 'card-wrapper-row subs'} key={index}>
                  {isLoaded === true && (
                    <>
                      {index === 0 && (
                        <div className="title-warpper">
                          <TitleHolder text={t('account.subscription_title')} styles="subtitle" />
                        </div>
                      )}
                      <div className="arrow-wrap" onClick={() => handleCollapse(item.reference)}>
                        <div className={selected === item.reference ? 'arrow rotate' : 'arrow'}>
                          <Icon icon={'arrow'} />
                        </div>
                      </div>
                      <Col lg={6} md={12} className="card-label-wrapper spreader content">
                        {/*Row one*/}
                        <div className="card-row">
                          <div className="card-label-row">
                            <div className="details-wrap">
                              <div className="label">{t('account.subscription_details')}</div>
                            </div>
                            <div className="value value-strong">
                              {item.product} {!isItemActive(item) && <img src={expired} alt="subscription expired" />}
                            </div>
                            <div className="macs bold">
                              {item.macSeats !== 0 && !isIPPItem(item) && (
                                <>
                                  {item.macSeats} {t('plan.macs')}
                                </>
                              )}
                              {item.winSeats !== 0 && !isIPPItem(item) && (
                                <>
                                  {item.winSeats} {t('plan.pcs')}
                                </>
                              )}
                              {isIPPItem(item) && (
                                <>
                                  {item.macSeats} {t('devices.title')}
                                </>
                              )}
                            </div>
                            <div className="years bold">
                              {item?.period / 12} {t('plan.year')}{' '}
                              {isItemActive(item) && currencyFormatter.format(item?.renewalPrice, { code: item?.renewalPriceCurrency })}{' '}
                              {isItemActive(item) && t('plan.yearly')}
                            </div>
                          </div>
                          {!isIPPItem(item) && (
                            <>
                              {isItemActive(item) && (
                                <div className="active-section-wrapper">
                                  <div
                                    className="change-plan"
                                    onClick={() => {
                                      history.push(`/account/plan?item=${item.reference}`, 'inner')
                                    }}
                                  >
                                    {t('account.upgrade_plan')}
                                  </div>
                                  	{/* AW-3373 */}
                                	{item.licenses.length == 1 && (
                                  	<>
                                  		<div className="download-installer">
                                    		<a color="success" href={`${item.productDownloadURL}`} target="_blank" rel="noreferrer" className="link">{t('account.download_installer')}</a>
                                  		</div>
                                  	</>
                                  )}
                                </div>
                              )}
                              {/*Row two */}
                              <div className="card-row">
                              	{/* AW-3373 */}
                              	{item.licenses.map((license, licenseIndex) => (
                              	<>
                              	  	<div className="separator-md"></div>
                              	  
                                	<div className="card-label-row">
                                  		<div className="label">{t('account.serial_number')}</div>
                                  		<div className="value">{license.serialNumber}</div>
                                    </div>
										
									<div className="margin-top-minus-md">
                                		{item.isActive && item.licenses.length > 1 && license.platform ==='mac' && (
                                  		<>
                                  			<div className="download-installer">
                                  				<a color="success" href={`${license.downloadURL}`} target="_blank" rel="noreferrer" className="link">{t('account.download_mac_installer')}</a>
                                    		</div>
                                    	</>
                                   		)}
                                		{item.isActive && item.licenses.length > 1 && license.platform ==='win' && (
                                  		<>
                                  			<div className="download-installer">
                                  				<a color="success" href={`${license.downloadURL}`} target="_blank" rel="noreferrer" className="link">{t('account.download_win_installer')}</a>
                                    		</div>
                                    	</>
                                   		)}
                                   	</div>        
                                </>
                                ))}
                                
								 <div className="separator-lg"></div>
                                
                                 <Link color="success" to="/devices">
                                  {t('account.manage_devices')}
                                </Link>
                              </div>
                              {/*  End Row two */}
                            </>
                          )}
                          {isIPPItem(item) && (        
                            <IPP key={index}
                              item={item.license}
                              index={index}
                              toggleModal={toggleModal}
                              serialNumber={serialNumber}
                              username={ippInfo[index]['l']===0 ? ippInfo[index]['u'] : '*********' }
                              ippPass={ippInfo[index]['l']===0 ? ippInfo[index]['p'] : '*********' }
                              locked={ippInfo[index]['l']}
                            />
                          )}
                          {isIPPItem(item) && ( 
                          <>
                          	  	<div className="download-installer">
                                    <a color="success" href={`${item.productDownloadURL}`} target="_blank" rel="noreferrer" className="link">{t('account.download_installer')}</a>
                                </div>
                           </> 
                          )}
                        </div>
                        {/*  End Row one */}

                        {/* Row three */}
                        <div className="card-row">
                          <div className="card-label-row">
                            <div className="label">{t('account.payment_method')}</div>
                            <div className="value">{item?.invoices[item?.invoices.length - 1]?.paymentType}</div>
                          </div>
                          <div className="active-section-wrapper">
                          <a color="success" target="_blank" rel="noopener" href={item.customerURL}>
                            {t('account.manage_subscription')}
                          </a>
                          {/* AW-3325 */}
                          {isItemActive(item) && (
                          	<div className="cancel-subscription" onClick={() => {
                                  setCurrSubscription(item.reference)
                            }}><div><CancelSubscriptionModal
                          	 		trigger={t('account.cancel_subscription')}
                                    showInformative={true} 
                                    callback={(e) => handleCancelSubscription(currSubscription)}
                                    dialog={true}
                          	 /></div></div>
                          )}
                          </div>
                        </div>
                        {/*  End Row three */}
                                                
                      </Col>

                      <Col lg={5} md={12} className="card-label-wrapper offset-lg-1 invoice-history-wrap content">
                        {/* Card row */}

                        <div className="card-row renewal-date">
                          <div className="label billing-details">
                            <div className="value date">
                              {t('account.renewal_date')}
                              <div className="date-wrap">
                                {isItemActive(item) ? item.nextDate : <span className="no_renewal">{t('account.no_renewal')}</span>}
                                {isItemActive(item) && (
                                  <div className="link">
                                    <Link color="success" to="/invoices">
                                      {t('account.billing_details')}
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  End card row */}
                        <div className="card-row invoice-history">
                          <div className="card-label-row">
                            <div className="label">{t('account.invoice_history')}</div>
                          </div>
                          {item.invoices.map((invoice, index) => (
                            <div className="invoice-history-item " key={index}>
                              <>
                                <div className="label date">{invoice.date.split(' ')[0]}</div>
                                <>
                                  {invoice.downloadURL ? (
                                    <div className="download-wrap">
                                      {isMobile ? (
                                        <span color="success" className="link" onClick={() => savePdf(invoice.downloadURL)}>
                                          {t('account.download_invoice')}
                                        </span>
                                      ) : (
                                        <a color="success" href={`${invoice.downloadURL}`} target="_blank" rel="noreferrer" className="link">
                                          {t('account.download_invoice')}
                                        </a>
                                      )}
                                    </div>
                                  ) : (
                                    <span>{t('account.no_invoices')}</span>
                                  )}
                                </>
                              </>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              ))}
            </Container>
            {ippModal ? (
              <IPP_Modal
                handleinput={handleinput}
                loginBtn={loginBtn}
                passwordRef={passwordRef}
                emailRef={emailRef}
                confPassword={confPassword}
                password={password}
                autofillFocused={autofillFocused}
                handleIppReply={handleIppReply}
                setIppModal={setIppModal}
              />
            ) : (
              ''
            )}
            {error && (
              <Row className="card-wrapper-row">
                <ErrorHandler />
              </Row>
            )}
          </>
        </ContentHolder>
      </div>
    </>
  )
}

export default Account
